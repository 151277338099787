@mixin custom-card-color($name,$color) {
  button.#{$name}-button-override {
    background-color: $color;
    border-color: $color;
  }
  button.#{$name}-button-override:disabled {
    background-color: $color !important;
    border-color: $color !important;
  }
  button.#{$name}-button-override:hover:not(:disabled) {
    background-color: darken($color,7.5%) !important;
    border-color: darken($color,7.5%) !important;
  }
  span.#{$name}-button-override {
    color: $color !important;
  }
}
@mixin custom-card-gradient-color($name,$color1,$color2) {
  button.#{$name}-button-override {
    background: linear-gradient($color1,$color2);
    border-color: transparent;
    border-top-color: $color1;
    border-bottom-color: $color2;
  }
  button.#{$name}-button-override:disabled {
    background: linear-gradient($color1,$color2);
    border-color: transparent;
    border-top-color: $color1;
    border-bottom-color: $color2;
  }
  button.#{$name}-button-override:hover:not(:disabled) {
    background: linear-gradient(darken($color1,7.5%),darken($color2,7.5%));
    border-color: transparent;
    border-top-color: darken($color1,7.5%);
    border-bottom-color: darken($color2,7.5%);
  }
  span.#{$name}-button-override {
    background-image: linear-gradient($color1,$color2);
    color: transparent !important;
    -webkit-background-clip: text;
    //noinspection CssInvalidPropertyValue
    background-clip: text;
  }
}
@include custom-card-color("curse",#563d7c);
@include custom-card-color("project", #da5286);
@include custom-card-color("duration",#de7a00);
@include custom-card-color("artifact", #fba358);
@include custom-card-color("ruins", #7d3600);
@include custom-card-color("way", #6ae3ff);
@include custom-card-color("event", #000000);
@include custom-card-gradient-color("action-victory",#868e96,#28a745);
@include custom-card-gradient-color("action-treasure",#868e96,#ffc107);
@include custom-card-gradient-color("action-shelter", #dc3545, #868e96);
@include custom-card-gradient-color("duration-night",#343a40,#de7a00);
@include custom-card-gradient-color("reaction-shelter", #dc3545, #007bff);
@include custom-card-gradient-color("victory-shelter", #dc3545, #28a745);
.btn {
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,opacity .15s ease-in-out;
}
@font-face {
  font-family: trajan-pro-3;
  src: url("/font/trajan.ttf")
}
@font-face {
  font-family: TrajanPro-Bold;
  src: url("/font/TrajanPro-Bold.otf")
}
.btn:disabled {
  opacity: 0.4;
}

//noinspection CssInvalidPropertyValue
.middle-align-text {
  vertical-align: middle;
  vertical-align: -webkit-baseline-middle;
  vertical-align: -moz-middle-with-baseline;
}
.dominion-font {
  font-family: "TrajanPro-Bold", serif;
  font-size: 24px;
}
.dominion-font-small {
  font-family: trajan-pro-3, serif;
}
.btn-group {
  display: flex;
  margin: 5px 0;
}
.force-image::after {
  content: url("/img/CoinHighRes.png");
}
@keyframes button-flash {
  0% {
    filter: saturate(1.2) brightness(1.2);
  }
  100% {
    filter: saturate(0.8) brightness(0.8);
  }
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4)
}
.modal-loss::after {
  content: "";
  position: absolute;
  top: 0; left: 0;
  width: 100%; height: 100%;
  background-image: url("/img/loss.jpg");
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
  filter: opacity(50%);
  z-index: 0;
}
.modal-win::after {
  content: "";
  position: absolute;
  top: 0; left: 0;
  width: 100%; height: 100%;
  background-image: url("/img/win.jpg");
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
  filter: opacity(50%);
  z-index: 0;
}
%modal {
  position: absolute;
  top: 40px;
  left: 40px;
  right: 40px;
  bottom: 40px;
  background-color: #fff;
  border-radius: 4px;
}
.modal-loss {
  @extend %modal;
}
.modal-win {
  @extend %modal;
}
.modal-username {
  @extend %modal;
  top: 40px;
  bottom: 50%;
  left: 25%;
  right: 25%;
  background: none !important;
}
.modal-confirm {
  @extend %modal;
  top: 40px;
  bottom: 50%;
  left: 33%;
  right: 33%;
  background: none !important;
}
.btn-disabled {
  opacity: 0.4;
  cursor: not-allowed !important;
  user-select: none;
  box-shadow: none !important;
  outline: none !important;
}